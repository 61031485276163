<app-section [section_type]="'profile'">
  <div class="row">
    <div class="col-md-4">
      <app-image
        [src]="'../../assets/images/home/portrait.jpeg'"
        [alt]="'Connor Blair'"
        [size]="'medium'"
        [rounded]="true"
        [link]="''"
      >
      </app-image>
    </div>
    <div class="col-md-8">
      <h1>Connor Blair</h1>
      <h4 class="caption">Senior Developer</h4>
      <p class="contact-info">
        <a class="link" href="mailto:connorblairmn@gmail.com">
          <mat-icon inline="true">email</mat-icon> connorblair@protonmail.com
        </a>
        <br />
        <!-- <a class="link" href="tel:+1-952-217-7258">
          <mat-icon inline="true">local_phone</mat-icon> +1-952-217-7258
        </a> -->
      </p>
      <a class="link" href="https://www.linkedin.com/in/connor-blair1">
        <app-linkedin></app-linkedin>
      </a>
      <a class="link" href="https://medium.com/@connorblair1">
        <app-medium></app-medium>
      </a>
      <a class="link" href="https://github.com/connorblairmn">
        <app-github></app-github>
      </a>
    </div>
  </div>
</app-section>
<!-- Background -->
<app-resume-section
  [title]="'Background'"
  [icon]="'portrait'"
  [items]="background"
>
</app-resume-section>

<!-- Education -->
<app-resume-section
  [title]="'Education'"
  [icon]="'school'"
  [items]="degreesArray"
>
</app-resume-section>

<!-- Enterprise Work Experience-->
<app-timeline></app-timeline>

<!-- Startup Work Experience -->
<app-startup></app-startup>

<!-- Engineering Certifications -->
<app-resume-section
  [title]="'Engineering Certifications'"
  [icon]="'code'"
  [items]="highlightEngineeringCerts"
  [allItems]="engineeringCertifications"
>
</app-resume-section>

<!-- Business Certifications -->
<app-resume-section
  [title]="'Business Certifications'"
  [icon]="'business'"
  [items]="highlightBusinessCertifications"
  [allItems]="businessCertifications"
>
</app-resume-section>

<!-- Articles -->
<app-resume-section
  [title]="'Articles'"
  [icon]="'library_books'"
  [items]="highlightArticles"
  [allItems]="articles"
>
</app-resume-section>

<!-- Contact me  -->
<app-section [section_type]="'footer'">
  <div class="row footer-content">
    <div class="col-md-6 offset-md-3">
      <div class="footer-in">
        <h2>Contact Me</h2>
        <p class="contact-info">
          <a class="link" href="mailto:connorblairmn@gmail.com">
            <mat-icon inline>email</mat-icon> connorblairmn@gmail.com
          </a>
          <!-- <a class="link" href="tel:+1-952-217-7258">
            <mat-icon inline>local_phone</mat-icon> +1-952-217-7258
          </a> -->
        </p>
      </div>
    </div>
    <div class="col-md-3 offset-md-3"></div>
  </div>
</app-section>
