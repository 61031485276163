<div class="image-container">
  <a [href]="link">
    <img
      [ngClass]="{
        large: size === 'large',
        medium: size === 'medium',
        small: size === 'small',
        round: rounded
      }"
      [src]="src"
      [alt]="alt"
    />
  </a>
</div>
