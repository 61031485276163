<svg
  width="500px"
  height="500px"
  viewBox="0 0 500 500"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g stroke="none" stroke-width="1" fill-rule="evenodd">
    <g fill-rule="nonzero">
      <path
        d="M395.833333,0 L104.166667,0 C46.6458333,0 0,46.6458333 0,104.166667 L0,395.833333 C0,453.354167 46.6458333,500 104.166667,500 L395.833333,500 C453.375,500 500,453.354167 500,395.833333 L500,104.166667 C500,46.6458333 453.375,0 395.833333,0 Z M166.666667,395.833333 L104.166667,395.833333 L104.166667,166.666667 L166.666667,166.666667 L166.666667,395.833333 Z M135.416667,140.25 C115.291667,140.25 98.9583333,123.791667 98.9583333,103.5 C98.9583333,83.2083333 115.291667,66.75 135.416667,66.75 C155.541667,66.75 171.875,83.2083333 171.875,103.5 C171.875,123.791667 155.5625,140.25 135.416667,140.25 Z M416.666667,395.833333 L354.166667,395.833333 L354.166667,279.083333 C354.166667,208.916667 270.833333,214.229167 270.833333,279.083333 L270.833333,395.833333 L208.333333,395.833333 L208.333333,166.666667 L270.833333,166.666667 L270.833333,203.4375 C299.916667,149.5625 416.666667,145.583333 416.666667,255.020833 L416.666667,395.833333 Z"
      />
    </g>
  </g>
</svg>
