import { Component, OnInit } from '@angular/core';
import { STARTUP_EXPERIENCES } from '../../data/experiences';
import { Experience } from '../../types/experience';

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.scss'],
})
export class StartupComponent implements OnInit {
  startupExperiences: Experience[] = STARTUP_EXPERIENCES;
  constructor() {}

  ngOnInit(): void {}
}
