import { Experience } from '../types/experience';

export const EXPERIENCES: Experience[] = [
  {
    name: 'VoiceFoundry, a TTEC Digital Company',
    time: '2021-Present',
    class: 'voicefoundry-img',
    position: 'Senior Developer',
    keyPoints: [
      'Developing AWS software solutions to migrate on-premise call centers to the cloud.',
      'Developed an Amazon Connect screen recording solution, that was later covered to a SaaS offering, to help clients improve customer service using Angular, Python Lambda functions, and a Windows application.',
      'Architected a transcription service that generates transcripts in a movie script format with a custom vocabulary using AWS Step Functions, AWS Lambda, Amazon Transcribe, and Amazon Connect.',
    ],
    skills: ['AWS', 'Javascript', 'Python', 'TypeScript'],
  },
  {
    name: 'Compuware Corporation',
    time: '2019-2021',
    class: 'compuware-img',
    position: 'Software Developer I',
    keyPoints: [
      'Architected and led implementation of an enhanced automated testing framework using JCL and REXX, providing a 300% increase in unit test throughput.',
      'Delivered multiple enhancements in IBM Mainframe Assembler to improve IBM CICS support on an enterprise performance analysis tool.',
      'Technical lead for resolution of multiple customer issues using test driven development.',
      'Delivered training to more than 30 on-boarding engineers on IBM Mainframe Assembler, the z/OS operating system, and Compuware Products.',
    ],
    skills: [
      'JCL',
      'REXX',
      'Assembly',
      'CICS',
      'z/OS',
      'Abend-AID',
      'Xpediter',
      'File-AID',
      'Hiperstation',
      'Confluence',
    ],
  },
  {
    name: 'Compuware Corporation',
    time: '2018-2019',
    class: 'compuware-img',
    position: 'Associate Software Developer II',
    keyPoints: [
      'Pioneered an application that continuously measures relevant DevOps KPIs using Python, ElasticSearch, and AWS that is now used across the entire organization.',
      'Resolved technical issues at customer sites and helped the sales team answer technical questions producing a better customer experience.',
    ],
    skills: [
      'Db2',
      'ISPW',
      'Topaz',
      'ISPF',
      'Python',
      'ElasticSearch',
      'Kibana',
      'EC2',
      'Docker',
      'XML',
      'JIRA',
      'LINUX',
    ],
  },
  {
    name: 'Compuware Corporation',
    time: '2017-2018',
    class: 'compuware-img',
    position: 'Associate Software Developer I',
    keyPoints: [
      'Maintained and enhanced IBM Mainframe Assembler code for an enterprise performance management and analysis tool ensuing a higher quality product. ',
    ],
    skills: ['IPCS', 'DevOps', 'Agile', 'Dump Reading', 'Strobe'],
  },
  {
    name: 'Fairview Health Services',
    time: '2016-2016',
    class: 'fairview-img',
    position: 'Data Analyst Intern',
    keyPoints: [
      'Extracted medical data using SQL to produce dashboards in QlikView for a more comprehensive  evaluation of medical decisions.',
    ],
    skills: ['SQL', 'QlikView'],
  },
];

export const STARTUP_EXPERIENCES: Experience[] = [
  {
    name: 'Buzzed Beers LLC',
    time: '2019-2020',
    class: 'buzzed-img',
    position: 'CTO & Co-founder',
    keyPoints: [
      'Developed an iOS application using Swift, SwiftUI, and Amplify that was approved for distribution on the Apple App Store.',
      'Architected an AWS backend using DynamoDB, Lambda, S3, Pinpoint, Cognito, and other services generating a highly scalable and reliable service.',
      'Designed the business strategy collaboratively with my co-founder outlined in our business plan, marketing plan, and our go to market strategy.',
      'Created serverless microservices in Python using AWS Lambda, AWS Serverless Application Model (SAM), and the Stripe API to handle payment processing.',
    ],
    skills: [
      'Swift',
      'SwiftUI',
      'Typescript',
      'Stripe',
      'DynamoDB',
      'Lambda',
      'VPC',
      'S3',
      'Pinpoint',
      'Cognito',
      'CloudFormation',
      'IAM',
      'Amplify',
      'GraphQL',
      'Angular',
      'Git',
    ],
  },
];
