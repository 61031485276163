<div
  class="section"
  [ngClass]="{
    profile: section_type === 'profile',
    footer: section_type === 'footer'
  }"
>
  <div class="container">
    <ng-content></ng-content>
  </div>
</div>
