import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
  // Image attrs
  @Input() link: string = '#';
  @Input() src: string;
  @Input() alt: string;

  // Style attrs
  @Input() size: 'small' | 'medium' | 'large';
  @Input() rounded: boolean = false;
  constructor() {}

  ngOnInit(): void {}
}
