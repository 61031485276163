<app-section>
  <div class="row app-section">
    <div class="col-md-4">
      <h3 class="title">
        <mat-icon inline="true">{{ icon }}</mat-icon> {{ title }}
      </h3>
    </div>
    <div class="col-md-8">
      <!-- highlights or all items for small arrays-->
      <mat-card class="education-card" *ngFor="let tile of items">
        <!-- no link-->
        <div *ngIf="tile.class && tile.header && tile.subheader && !tile.url">
          <mat-card-header>
            <div mat-card-avatar [class]="tile.class"></div>
            <mat-card-title>{{ tile.header }}</mat-card-title>
            <mat-card-subtitle>{{ tile.subheader }}</mat-card-subtitle>
          </mat-card-header>
        </div>
        <!-- link -->
        <div *ngIf="tile.class && tile.header && tile.subheader && tile.url">
          <mat-card-header>
            <div mat-card-avatar [class]="tile.class"></div>
            <mat-card-title
              ><a [href]="tile.url">{{ tile.header }}</a></mat-card-title
            >
            <mat-card-subtitle>{{ tile.subheader }}</mat-card-subtitle>
          </mat-card-header>
        </div>
        <div *ngIf="tile.body">
          <mat-card-content>
            {{ tile.body }}
          </mat-card-content>
        </div>
      </mat-card>

      <!-- all items for large arrays-->
      <div *ngIf="showAllObs | async">
        <mat-card class="education-card" *ngFor="let tile of allItems">
          <!-- no link-->
          <div *ngIf="tile.class && tile.header && tile.subheader && !tile.url">
            <mat-card-header>
              <div mat-card-avatar [class]="tile.class"></div>
              <mat-card-title>{{ tile.header }}</mat-card-title>
              <mat-card-subtitle>{{ tile.subheader }}</mat-card-subtitle>
            </mat-card-header>
          </div>
          <!-- link -->
          <div *ngIf="tile.class && tile.header && tile.subheader && tile.url">
            <mat-card-header>
              <div mat-card-avatar [class]="tile.class"></div>
              <mat-card-title
                ><a [href]="tile.url">{{ tile.header }}</a></mat-card-title
              >
              <mat-card-subtitle>{{ tile.subheader }}</mat-card-subtitle>
            </mat-card-header>
          </div>

          <div *ngIf="tile.body">
            <mat-card-content>
              {{ tile.body }}
            </mat-card-content>
          </div>
        </mat-card>
      </div>
      <!-- show all button-->
      <div *ngIf="allItems">
        <button mat-raised-button (click)="showAll()">
          {{ showAllText }}
        </button>
      </div>
    </div>
  </div>
</app-section>
