<div class="section timeline">
  <div class="overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h3 class="title">
          <mat-icon inline="true">work_outline</mat-icon> Enterprise Work
          Experience
        </h3>
      </div>
      <div class="col-md-8">
        <div class="row work-experience" *ngFor="let experience of experiences">
          <div class="col-md-3"></div>
          <div class="col-md-8 offset-md-1">
            <mat-card class="experience-card">
              <mat-card-header>
                <div mat-card-avatar [class]="experience.class"></div>
                <mat-card-title>{{ experience.position }}</mat-card-title>
                <mat-card-subtitle>{{ experience.name }}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <ul>
                  <div *ngFor="let point of experience.keyPoints">
                    <li class="description">{{ point }}</li>
                  </div>
                </ul>
                <mat-chip-list>
                  <div *ngFor="let skill of experience.skills">
                    <mat-chip class="chip-wrapper">{{ skill }}</mat-chip>
                  </div>
                </mat-chip-list>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
