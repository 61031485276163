import { Component, OnInit, Input } from '@angular/core';
import { ResumeSectionTile } from '../../types/resumeSectionTile';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-resume-section',
  templateUrl: './resume-section.component.html',
  styleUrls: ['./resume-section.component.scss'],
})
export class ResumeSectionComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  // TODO: do I need this???
  @Input() paddTop: boolean = true;
  @Input() paddBottom: boolean = true;
  //all data for small arrays and highlight data for large arrays
  @Input() items: ResumeSectionTile[];
  //input all data for large arrays
  @Input() allItems?: ResumeSectionTile[];

  //flag to show all items
  private _showAllItems: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  public showAllObs: Observable<boolean> = this._showAllItems.asObservable();
  //button text to display depending on the state
  private showAllNotEnabled = 'Show More';
  private showAllEnabled = 'Show Less';
  public showAllText: string = this.showAllNotEnabled;

  /*
    Function: Update the state that determines if we should show all items and update the button text.
  */
  showAll() {
    if (!this._showAllItems.value) {
      this.showAllText = this.showAllEnabled;
      this._showAllItems.next(true);
    } else {
      this.showAllText = this.showAllNotEnabled;
      this._showAllItems.next(false);
    }
  }

  constructor() {}

  ngOnInit(): void {}
}
