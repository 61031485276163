import { Component, OnInit } from '@angular/core';
import { EXPERIENCES } from '../../data/experiences';
import { Experience } from '../../types/experience';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  experiences: Experience[] = EXPERIENCES;

  constructor() {}

  ngOnInit(): void {}
}
