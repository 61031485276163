import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

/* import AmplifyUIAngularModule  */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// angular material components
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';

//custom components
import { SectionComponent } from './core/section/section.component';
import { ImageComponent } from './core/image/image.component';
import { ResumeSectionComponent } from './core/resume-section/resume-section.component';
import { GithubComponent } from './core/github/github.component';
import { LinkedinComponent } from './core/linkedin/linkedin.component';
import { MediumComponent } from './core/medium/medium.component';
import { TimelineComponent } from './core/timeline/timeline.component';
import { StartupComponent } from './core/startup/startup.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SectionComponent,
    ImageComponent,
    ResumeSectionComponent,
    GithubComponent,
    LinkedinComponent,
    MediumComponent,
    TimelineComponent,
    StartupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    /* configure app with AmplifyUIAngularModule */
    AmplifyUIAngularModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDividerModule,
    MatCardModule,
    MatChipsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
