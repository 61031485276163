import { Component, OnInit } from '@angular/core';
import {
  DEGREES,
  HIGHLIGHT_ENGINEERING_CERTS,
  ENGINEERING_CERTIFICATIONS,
  HIGHLIGHT_BUSINESS_CERTIFICATIONS,
  BUSINESS_CERTIFICATIONS,
} from '../data/education';
import { ResumeSectionTile } from '../types/resumeSectionTile';
import { HIGHLGHT_ARTICLES, ARTICLES } from '../data/articles';
import { BACKGROUND } from '../data/background';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  background: ResumeSectionTile[] = BACKGROUND;

  degreesArray: ResumeSectionTile[] = DEGREES;
  //array of highlights
  highlightEngineeringCerts: ResumeSectionTile[] = HIGHLIGHT_ENGINEERING_CERTS;
  //array of all engineering certifications
  engineeringCertifications: ResumeSectionTile[] = ENGINEERING_CERTIFICATIONS;

  highlightBusinessCertifications: ResumeSectionTile[] = HIGHLIGHT_BUSINESS_CERTIFICATIONS;

  businessCertifications: ResumeSectionTile[] = BUSINESS_CERTIFICATIONS;

  highlightArticles: ResumeSectionTile[] = HIGHLGHT_ARTICLES;
  articles: ResumeSectionTile[] = ARTICLES;

  constructor() {}

  ngOnInit(): void {}
}
