export const environment = {
  site: {
    baseURL: 'https://connorblair.com',
    email: 'connorblairmn@gmail.com', // Should be same as LinkedIn login
    name: 'Connor Blair',
    short_name: 'Connor',
    phone: '952.217.7258',
  },
  pages: {
    //   devto: {
    //     enabled: true,
    //     name: 'Articles',
    //     path: 'articles',
    //     username: 'michaelsolati',
    //     title: 'Articles | Michael Solati',
    //     header: 'Articles',
    //     description: 'Stories, guides, and tutorials for developers by me.',
    //     topics: ['writing', 'article']
    //   },
    github: {
      enabled: true,
      name: 'Code',
      path: 'code',
      username: 'connorblairmn',
      title: 'Code | Connor Blair',
      header: 'Code',
      description:
        "Some of the best code I've written, available for everyone!",
      topics: ['code', 'software', 'development'],
    },
    home: {
      enabled: true, // Never disables
      name: 'Home',
      path: '',
      username: 'connorblairmn', // For LinkedIn
      title: 'Home | Connor Blair',
      header: 'Connor Blair',
      description: null, // Leave empty to default to LinkedIn description
    },
  },
};
