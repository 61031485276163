<svg
  width="494px"
  height="497px"
  viewBox="0 0 494 497"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g stroke="none" stroke-width="1" fill-rule="evenodd">
    <g fill-rule="nonzero">
      <path
        d="M246.787879,0 C110.515152,0 0,113.953125 0,254.53125 C0,366.984375 70.7121212,462.375 168.787879,496.03125 C181.136364,498.375 185.636364,490.515625 185.636364,483.765625 C185.636364,477.734375 185.424242,461.71875 185.30303,440.484375 C116.651515,455.859375 102.166667,406.359375 102.166667,406.359375 C90.9393939,376.953125 74.7575758,369.125 74.7575758,369.125 C52.3484848,353.34375 76.4545455,353.65625 76.4545455,353.65625 C101.227273,355.453125 114.257576,379.890625 114.257576,379.890625 C136.272727,418.78125 172.030303,407.546875 186.090909,401.03125 C188.333333,384.59375 194.712121,373.375 201.757576,367.015625 C146.954545,360.59375 89.3333333,338.75 89.3333333,241.21875 C89.3333333,213.4375 98.9545455,190.703125 114.742424,172.921875 C112.19697,166.484375 103.727273,140.59375 117.166667,105.5625 C117.166667,105.5625 137.878788,98.71875 185.030303,131.65625 C204.712121,126 225.833333,123.1875 246.818182,123.078125 C267.787879,123.1875 288.893939,126 308.606061,131.65625 C355.727273,98.71875 376.409091,105.5625 376.409091,105.5625 C389.878788,140.59375 381.409091,166.484375 378.878788,172.921875 C394.69697,190.703125 404.242424,213.4375 404.242424,241.21875 C404.242424,339 346.530303,360.515625 291.560606,366.8125 C300.409091,374.671875 308.30303,390.203125 308.30303,413.953125 C308.30303,447.96875 308,475.421875 308,483.765625 C308,490.578125 312.454545,498.5 324.969697,496.015625 C422.969697,462.28125 493.621212,366.953125 493.621212,254.53125 C493.621212,113.953125 383.106061,0 246.787879,0"
      />
    </g>
  </g>
</svg>
