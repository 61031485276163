import { ResumeSectionTile } from '../types/resumeSectionTile';

//array of degrees
export const DEGREES: ResumeSectionTile[] = [
  {
    header: 'University of Illinois at Urbana-Champaign',
    subheader: 'Bachelor of Science (BS), Computer Engineering',
    class: 'uiuc-img',
  },
];

//highlights of engineering certifications
export const HIGHLIGHT_ENGINEERING_CERTS: ResumeSectionTile[] = [
  {
    header: 'AWS Certified Architect - Professional',
    subheader: 'Amazon Web Services (AWS)',
    class: 'aws-img',
  },
  {
    header: 'AWS Certified DevOps Engineer – Professional',
    subheader: 'Amazon Web Services (AWS)',
    class: 'aws-img',
  },
  {
    header: 'AWS Certified Developer - Associate',
    subheader: 'Amazon Web Services (AWS)',
    class: 'aws-img',
  },
  {
    header: 'AWS Certified Architect - Associate',
    subheader: 'Amazon Web Services (AWS)',
    class: 'aws-img',
  },
  {
    header: 'Machine Learning',
    subheader: 'Stanford University | Coursera',
    class: 'stanford-img',
  },
  {
    header: 'Getting started with TensorFlow 2',
    subheader: 'Imperial College London | Coursera',
    class: 'imperial-img',
  },
  {
    header: 'Customising your models with TensorFlow 2',
    subheader: 'Imperial College London | Coursera',
    class: 'imperial-img',
  },
];

//array of all engineering certifications
export const ENGINEERING_CERTIFICATIONS: ResumeSectionTile[] = [
  {
    header: 'Learning Amazon Connect: Create and Manage a Contact Center',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Go Essential Training',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Learning Terraform',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Angular Essential Training',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Angular: Building an Interface',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'AWS for Developers: AWS AppSync with GraphQL',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'iOS App Development: Test-Driven Development',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'AWS API Gateway with HTTP, Lambda, DynamoDB, and iOS',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Advanced Python',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'AWS for Architects: High Availability and Continuous Deployment',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Amazon Web Services: Design and Implement Systems',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Deploying Docker to AWS',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Learning Jenkins',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Dive Into Orchestration with Docker Swarm',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Learning Docker',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Cloud Architecture: Advanced Concepts',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Cloud Architecture: Advanced Concepts',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Techniques and Concepts of Big Data',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Up and Running with QlikView',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
];

export const HIGHLIGHT_BUSINESS_CERTIFICATIONS: ResumeSectionTile[] = [
  {
    header: 'Business Strategy',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Applications of Everyday Leadership',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Managing the Organization',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
];

export const BUSINESS_CERTIFICATIONS: ResumeSectionTile[] = [
  {
    header: 'Strategic Innovation: Managing Innovation Initiatives',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header:
      'Strategic Innovation: Building and Sustaining Innovative Organizations',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Corporate Strategy',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Entrepreneurship I: Laying the Foundation',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Entrepreneurship II: Preparing for Launch',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Designing the Organization',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Digital Media and Marketing Strategies',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Digital Marketing Analytics in Theory',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Marketing in an Analog World',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Digital Media and Marketing Principles',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Foundations of Everyday Leadership',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Digital Marketing Analytics in Practice',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Marketing in a Digital World',
    subheader: 'University of Illinois at Urbana-Champaign | Coursera',
    class: 'uiuc-img',
  },
  {
    header: 'Agile at Work: Reporting with Agile Charts and Boards',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'DevOps Foundations: Continuous Delivery/Continuous Integration',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'DevOps Foundations: Infrastructure Automation',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Strategic Agility',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Agile Product Owner Role: Foundations',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Agile Project Management Foundations',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
  {
    header: 'Agile at Work: Getting Better with Agile Retrospectives',
    subheader: 'LinkedIn Learning',
    class: 'linkedin-img',
  },
];
