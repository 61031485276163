import { ResumeSectionTile } from '../types/resumeSectionTile';

//array of degrees
export const HIGHLGHT_ARTICLES: ResumeSectionTile[] = [
  {
    header: 'Getting Started With ISPW Tutorial',
    subheader: 'Medium',
    class: 'medium-img',
    url:
      'https://medium.com/@connorblair1/getting-started-with-ispw-953f0165522a',
  },
  {
    header: 'Getting Started With Abend-AID Tutorial',
    subheader: 'Medium',
    class: 'medium-img',
    url:
      'https://medium.com/@connorblair1/getting-started-with-abend-aid-e3369758315e',
  },
  {
    header:
      'The Importance of Developer-to-Developer Communication with Customers',
    subheader: 'Compuware',
    class: 'compuware-img',
    url:
      'https://www.compuware.com/developer-to-developer-communication-with-customers/',
  },
];

export const ARTICLES: ResumeSectionTile[] = [
  {
    header: 'Getting Started With Compuware Source Support Tutorial',
    subheader: 'Medium',
    class: 'medium-img',
    url:
      'https://medium.com/@connorblair1/getting-started-with-compuware-source-support-15dd3bfab910',
  },
  {
    header: 'How to Configure Abend-AID on Topaz',
    subheader: 'Medium',
    class: 'medium-img',
    url:
      'https://medium.com/@connorblair1/how-to-configure-abend-aid-on-topaz-8224daacdfb0',
  },
  {
    header: 'How to define a Monitoring Control Table (MCT) in CICS',
    subheader: 'Medium',
    class: 'medium-img',
    url:
      'https://medium.com/@connorblair1/how-to-define-a-monitoring-control-table-mct-in-cics-f99e7b8366bf',
  },
  {
    header: 'How to Execute SDSF Commands from Topaz',
    subheader: 'Medium',
    class: 'medium-img',
    url:
      'https://medium.com/@connorblair1/how-to-execute-sdsf-commands-from-topaz-c89d81894300',
  },
  {
    header: 'Allocating an APF Authorized Load Dataset on Topaz',
    subheader: 'Medium',
    class: 'medium-img',
    url:
      'https://medium.com/@connorblair1/allocating-an-apf-authorized-load-dataset-on-topaz-abe31664a5d1',
  },
  {
    header: 'Getting Started with Hiperstation Tutorial',
    subheader: 'Medium',
    class: 'medium-img',
    url:
      'https://medium.com/@connorblair1/getting-started-with-hiperstation-9d2abd94c92e',
  },
  {
    header: 'Getting Started With ISPW Compare on Topaz',
    subheader: 'Medium',
    class: 'medium-img',
    url:
      'https://medium.com/@connorblair1/getting-started-with-ispw-compare-on-topaz-85fd8399653d',
  },
];
