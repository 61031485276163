import { ResumeSectionTile } from '../types/resumeSectionTile';

//background
export const BACKGROUND: ResumeSectionTile[] = [
  {
    body: `An energetic and resourceful DevOps Software Engineer
    with over three years of experience developing high-quality software in an
    Agile work environment. Expertise in Assembler, Swift, Python, Go,
    Javascript, and C on AWS and IBM z/OS.`,
  },
];
